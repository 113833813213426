export enum ProductAttributeFrontendMeaning {
    Default,
    Color,
}

export interface IProductAttribute {
    attributeId: number;
    type: number;
    label: string;
    frontendMeaning: ProductAttributeFrontendMeaning;
    isPrimary: boolean;
    values: IProductAttributeValue[];
}

export interface IProductAttributeValue {
    attributeId: number;
    label: string;
    value: number | string;
    colorValue?: string | null;
}
