import { useEffect, useState } from 'react';
import { getAllMediaImages } from '../utills/get-media-image';
import { IUseMedia } from '../models/use-media.model';
import { IMediaImage } from '../models/media.model';

export interface IUseMultipleMedia extends Omit<IUseMedia, 'relation' | 'fallback'> {
    relations: number[];
}

export default function useMultipleMedia({ media, relations }: IUseMultipleMedia): IMediaImage[] {
    const [mediaImages, setMediaImages] = useState<IMediaImage[]>(
        getAllMediaImages(media, relations)
    );

    useEffect(() => {
        setMediaImages(getAllMediaImages(media, relations));
    }, [media, relations]);

    return mediaImages;
}
