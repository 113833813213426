import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { imgBox, modalButton, img, fadeIn, miniatures } from './gallery.module.scss';
import { config } from '../../config';
import useMultipleMedia from '../../hooks/use-multiple-media';
import { addModalAction } from '../../redux/actions/actions-modals';

import GalleryModal from './custom-modals/gallery-modal';
import GalleryMiniatureList from '../molecules/gallery-miniature-list';
import RatioImage from '../atoms/ratio-image';

const { mediaRelationsMap } = config;
const defaultRelations = [mediaRelationsMap.mainImage, mediaRelationsMap.galleryItem];

const Gallery = ({ className, media, relations }) => {
    const dispatch = useDispatch();
    const gallery = useMultipleMedia({ media, relations });
    const sortedGallery = gallery.sort((a, b) => a.sequence - b.sequence);
    const [activeIndex, setActiveIndex] = useState(0);
    const [animate, setAnimate] = useState(true);

    const handleChange = (index) => {
        setAnimate(false);
        setActiveIndex(index);
    };

    const handleShowModal = () => {
        dispatch(
            addModalAction({
                renderComponent: (modalId) => (
                    <GalleryModal
                        modalId={modalId}
                        images={sortedGallery}
                        activeIndex={activeIndex}
                    />
                ),
            })
        );
    };

    useEffect(() => {
        if (!animate) {
            setAnimate(!animate);
        }
    }, [animate]);

    return (
        <div className={className}>
            <div className={imgBox}>
                <button className={modalButton} onClick={handleShowModal}>
                    <RatioImage
                        src={sortedGallery[activeIndex].url}
                        className={`${img} ${animate ? fadeIn : ''}`}
                        alt={sortedGallery[activeIndex].alt}
                    />
                </button>
                {sortedGallery.length > 1 && (
                    <GalleryMiniatureList
                        className={miniatures}
                        onClick={(index) => handleChange(index)}
                        activeIndex={activeIndex}
                        mediaImages={sortedGallery}
                    />
                )}
            </div>
        </div>
    );
};

Gallery.propTypes = {
    className: PropTypes.string,
    media: PropTypes.array.isRequired,
    relations: PropTypes.array,
};

Gallery.defaultProps = {
    className: '',
    relations: defaultRelations,
};

export default Gallery;
