import React, { useState } from 'react';

import { container } from './faq.module.scss';
import FaqEmotics from '../../assets/images/svg/faq-emotics.svg';
import { useFaq } from '../../hooks/use-faq';
import { getLessImportantHeading } from '../../utills/get-less-important-heading';

import Emotic from '../atoms/emotic';
import Title from '../atoms/title';
import SubTitle from '../atoms/subtitle';
import SingleFaq from '../molecules/single-faq';

interface IFaqProps {
    className?: string;
    TitleTag?: React.ElementType;
}

const Faq: React.FC<IFaqProps> = ({ className = '', TitleTag = 'h2' }) => {
    const faqs = useFaq();
    const [initialFaqId] = useState(readFaqId());

    return (
        <div className={`${container} ${className}`}>
            <div>
                <Emotic emotic={FaqEmotics} />
                <Title Tag={TitleTag}>{copy.title}</Title>
                <SubTitle Tag={getLessImportantHeading(TitleTag)}>{copy.subtitle}</SubTitle>
            </div>
            <div>
                {faqs.map((faq) => (
                    <SingleFaq
                        key={faq.faqId}
                        faq={faq}
                        isOpen={initialFaqId && faq.faqId === initialFaqId}
                    />
                ))}
            </div>
        </div>
    );
};

function readFaqId() {
    if (typeof window === 'undefined') return;
    return parseInt(window.location.hash.replace('#', ''));
}

const copy = {
    title: 'Najczęściej zadawane pytania',
    subtitle:
        'Tutaj znajdziesz odpowiedzi na najczęsciej zadawane pytania przez naszych podopiecznych i nie tylko!',
};

export default Faq;
