import React from 'react';

import {
    attributeOption,
    attributeContainer,
    productAttributeList,
} from './product-attribute-list.module.scss';
import {
    IProductAttribute,
    ProductAttributeFrontendMeaning,
} from '../../models/product-attribute.model';
import { IOption } from '../../models/option.model';
import { TAttributeValueObject } from '../../hooks/use-product-variants';

import PillSwitcher, { IPillSwitcherProps } from '../molecules/pill-switcher';
import ColorSwitcher, { IColorSwitcherProps } from '../molecules/color-switcher';

type TItemProps = IPillSwitcherProps | IColorSwitcherProps;
type TActiveAttributes = Record<number, TAttributeValueObject>;
type TAllowedAttributesValues = Record<number, number[]>;

export interface IOptionWithAttributeData extends IOption {
    attributeId: number;
    isPrimary: boolean;
}

export interface IProductAttributeListProps {
    attributes: IProductAttribute[];
    activeAttributes?: TActiveAttributes;
    allowedAttributesValues?: TAllowedAttributesValues;
    onAttributeChange?: (option: IOptionWithAttributeData) => void;
    show?: boolean;
    itemProps?: TItemProps | ((attribute: IProductAttribute) => TItemProps);
}

export default function ProductAttributeList({
    attributes,
    activeAttributes,
    allowedAttributesValues,
    onAttributeChange,
    show = false,
    itemProps: uncheckedItemProps,
}: IProductAttributeListProps) {
    const handleChange = (attribute: IProductAttribute) => (option: IOption) => {
        if (onAttributeChange) {
            onAttributeChange({
                ...option,
                attributeId: attribute.attributeId,
                isPrimary: attribute.isPrimary,
            });
        }
    };

    return (
        <>
            {show && attributes.length > 0 && (
                <div className={productAttributeList}>
                    {attributes.map((attribute) => {
                        const Component = attributeComponentMap[attribute.frontendMeaning || 0];
                        const itemProps =
                            typeof uncheckedItemProps === 'function'
                                ? uncheckedItemProps(attribute)
                                : uncheckedItemProps;

                        return (
                            <Component
                                key={`attribute-${attribute.attributeId}`}
                                options={attribute.values}
                                onChange={handleChange(attribute)}
                                className={attributeContainer}
                                optionClassName={attributeOption}
                                title={attribute.label}
                                activeOption={findActiveOption(activeAttributes, attribute)}
                                allowedValues={getAllowedValues(allowedAttributesValues, attribute)}
                                {...itemProps}
                            />
                        );
                    })}
                </div>
            )}
        </>
    );
}

const attributeComponentMap = {
    [ProductAttributeFrontendMeaning.Default]: PillSwitcher,
    [ProductAttributeFrontendMeaning.Color]: ColorSwitcher,
};

const findActiveOption = (
    activeAttributes: TActiveAttributes | undefined,
    attribute: IProductAttribute
) => {
    return (
        activeAttributes &&
        attribute.values.find(
            (value) => activeAttributes[attribute.attributeId]?.value === value.value
        )
    );
};

const getAllowedValues = (
    allowedAttributesValues: TAllowedAttributesValues | undefined,
    attribute: IProductAttribute
) => {
    return (allowedAttributesValues && allowedAttributesValues[attribute.attributeId]) || [];
};
