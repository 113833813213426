import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';

import {
    button,
    open as openClass,
    header,
    arrow,
    answer,
    question,
} from './single-faq.module.scss';
import FaqArrow from 'assets/images/svg/faq-arrow.svg';

import Markdown from '../hoc/markdown';

const SingleFaq = ({ faq, isOpen: initiallyOpen }) => {
    const [open, setOpen] = useState(initiallyOpen);

    const handleClick = () => setOpen(!open);

    return (
        <div id={faq.faqId} className={`${question} ${open ? open : ''}`}>
            <button className={button} onClick={handleClick} onKeyUp={handleClick}>
                <div className={header}>
                    <div>{faq.title}</div>
                    <div>
                        <FaqArrow className={`${arrow} ${open ? openClass : ''}`} />
                    </div>
                </div>
            </button>
            <AnimateHeight duration={500} height={open ? 'auto' : 0}>
                <Markdown className={`ck-content ${answer}`}>{faq.content}</Markdown>
            </AnimateHeight>
        </div>
    );
};

export default SingleFaq;
