import React, { useCallback } from 'react';
import hexRgb from 'hex-rgb';

import {
    button,
    active,
    titleText,
    colorContainer,
    colorSwitcher,
    checkmark,
} from './color-switcher.module.scss';
import Checkmark from '../../assets/images/svg/ok.svg';
import { IOption } from '../../models/option.model';

type TAllowedValues = (number | string)[];

export interface IColorSwitcherProps {
    options: (IOption & { colorValue?: string | null })[];
    title: string;
    onChange?: (color: IOption) => void;
    activeOption?: IOption;
    optionClassName?: string;
    className?: string;
    disabled?: boolean;
    allowedValues?: TAllowedValues;
}

export default function ColorSwitcher({
    options,
    title,
    onChange,
    activeOption,
    optionClassName,
    className,
    disabled,
    allowedValues,
}: IColorSwitcherProps) {
    const handleClick = useCallback(
        (color: IOption) => () => {
            if (onChange) {
                onChange(color);
            }
        },
        [onChange]
    );

    return (
        <div className={`${colorSwitcher} ${className}`}>
            <h6 className={`${titleText}`}>{title}</h6>
            <div className={colorContainer}>
                {options.map((item) => (
                    <button
                        key={`color-item-${item.value}-${item.label}`}
                        type="button"
                        className={`${button} ${getActiveOptionClassName({
                            activeOption,
                            option: item,
                            allowedValues,
                        })} ${optionClassName}`}
                        style={hexToCssVariables(item.colorValue)}
                        onClick={handleClick(item)}
                        disabled={disabled || !isValueAllowed(allowedValues, item.value)}
                    >
                        {activeOption?.value === item.value && <Checkmark className={checkmark} />}
                    </button>
                ))}
            </div>
        </div>
    );
}

interface IGetActiveOptionClassNameProps {
    allowedValues?: TAllowedValues;
    activeOption?: IOption;
    option: IOption;
}

const getActiveOptionClassName = ({
    allowedValues,
    activeOption,
    option,
}: IGetActiveOptionClassNameProps) => {
    if (allowedValues && allowedValues.length === 0) {
        return '';
    }

    if (activeOption?.value === option.value) {
        return active;
    }

    return '';
};

const hexToCssVariables = (color?: string | null) => {
    if (!color) {
        return {};
    }

    return Object.entries(hexRgb(color)).reduce(
        (acc, [colorName, colorValue]) => ({
            ...acc,
            [`--${colorName}`]: colorValue,
        }),
        {}
    );
};

const isValueAllowed = (allowedValues: TAllowedValues | undefined, value: string | number) => {
    if (!allowedValues) {
        return true;
    }

    return allowedValues.includes(value);
};
