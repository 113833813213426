import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, WindowLocation } from '@reach/router';
import { navigate } from 'gatsby';

import { arrayToObject, groupBy } from '../utills/data-convertion';
import omit from '../utills/omit';
import pick from '../utills/pick';
import { IProductAttribute, IProductAttributeValue } from '../models/product-attribute.model';
import { IProductVariant, IProductVariantAttributeValue } from '../models/product-variant.model';
import { IOptionWithAttributeData } from '../components/organisms/product-attribute-list';

export type TAttributeValueObject = Pick<IProductAttributeValue, 'attributeId' | 'value'>;
type TVariantAttributeValue = Pick<
    IProductVariantAttributeValue,
    'variantId' | 'attributeId' | 'value'
>;
type TGroupedVariantAttributeValuesMap = Record<number, TVariantAttributeValue[]>;

export interface IUseProductVariantsProps {
    variants: IProductVariant[];
    attributesDefinitions: IProductAttribute[];
}

export default function useProductVariants({
    variants,
    attributesDefinitions,
}: IUseProductVariantsProps) {
    const variantAttributeValuesMap = useMemo(() => {
        return mapVariantAttributesValues(variants);
    }, [variants]);

    const primaryAttributeFirstValue = useMemo(() => {
        return findPrimaryAttributeFirstValue(attributesDefinitions);
    }, [attributesDefinitions]);

    const location = useLocation();

    const [variant, setVariant] = useState<IProductVariant | undefined>(variants[0]);

    const [attributesValues, setAttributesValues] = useState<
        Record<number, TAttributeValueObject> | undefined
    >(
        setDefaultAttributesValuesByAttributeObject(
            variantAttributeValuesMap,
            primaryAttributeFirstValue
        )
    );

    const [allowedAttributesValues, setAllowedAttributesValues] = useState<
        Record<number, number[]>
    >();

    const handleAttributeChange = useCallback(
        async (attribute: IOptionWithAttributeData) => {
            let newAttributes: Record<number, TAttributeValueObject> | undefined;

            if (attribute.isPrimary) {
                newAttributes = setDefaultAttributesValuesByAttributeObject(
                    variantAttributeValuesMap,
                    attribute
                );
            } else {
                newAttributes = {
                    ...attributesValues,
                    [attribute.attributeId]: pick(attribute, ['attributeId', 'value']),
                };
            }

            if (!newAttributes) {
                return;
            }

            const variantId = findVariantId(variantAttributeValuesMap, newAttributes);

            if (!variantId || isVariantInUrl(location, variantId)) {
                return;
            }

            await navigateToVariant(location, variantId);
        },
        [attributesValues, location, variantAttributeValuesMap]
    );

    useEffect(() => {
        if (!primaryAttributeFirstValue || variants.length === 0) {
            return;
        }

        const urlVariantId = getUrlVariantId(location.search);
        const newVariant = variants.find((variant) => variant.variantId === urlVariantId);

        if (!urlVariantId || !newVariant) {
            (async () => await navigateToVariant(location, variants[0].variantId))();
            return;
        }

        setVariant(newVariant);

        const newAttributes = findAttributesValuesByVariantId(
            variantAttributeValuesMap,
            urlVariantId
        );
        setAttributesValues(newAttributes);

        setAllowedAttributesValues(
            getAllowedAttributesForAttributeValue(
                variantAttributeValuesMap,
                newAttributes[primaryAttributeFirstValue.attributeId]
            )
        );
    }, [location, variantAttributeValuesMap, primaryAttributeFirstValue, variants]);

    return {
        variant,
        attributesValues,
        allowedAttributesValues,
        handleAttributeChange,
    };
}

const mapVariantAttributesValues = (variants: IProductVariant[]) => {
    return variants
        .map<TVariantAttributeValue[]>(({ variantId, attributesValues }) => {
            return attributesValues.map((attributeValue) => ({
                variantId,
                ...pick(attributeValue, ['attributeId', 'value']),
            }));
        })
        .flat();
};

const findPrimaryAttributeFirstValue = (
    attributes: IProductAttribute[]
): TAttributeValueObject | undefined => {
    if (!attributes.length) return;

    let attribute = attributes.find((attribute) => attribute.isPrimary);
    if (!attribute) {
        attribute = attributes[0];
    }

    const value = attribute.values.sort((a, b) => Number(a.value) - Number(b.value))[0];

    return {
        attributeId: attribute.attributeId,
        value: Number(value),
    };
};

const findVariantId = (
    variantsAttributesValues: TVariantAttributeValue[],
    attributesValues: Record<number, TAttributeValueObject>
) => {
    const variantAttributeValuesByVariantId = getVariantsAttributesValuesByVariantId(
        variantsAttributesValues
    );

    return variantAttributeValuesByVariantId.find((variantValue) =>
        Object.values(attributesValues).every((attributeValue) =>
            findVariantByAttributeObject(variantValue, attributeValue)
        )
    )?.[0].variantId;
};

const getAllowedAttributesForAttributeValue = (
    variantsAttributesValues: TVariantAttributeValue[],
    attributeValueObject: TAttributeValueObject
): Record<number, number[]> => {
    const variantAttributeValuesByVariantId = getVariantsAttributesValuesByVariantId(
        variantsAttributesValues
    );
    const availableAttributesValues = variantAttributeValuesByVariantId.reduce(
        (acc, variantAttributesValues) => {
            if (findVariantByAttributeObject(variantAttributesValues, attributeValueObject)) {
                return [
                    ...acc,
                    ...omitVariantAttributesByAttributeValue(
                        variantAttributesValues,
                        attributeValueObject
                    ),
                ];
            }

            return [
                ...acc,
                ...getVariantAttributeValueByAttributeId(
                    variantAttributesValues,
                    attributeValueObject.attributeId
                ),
            ];
        },
        [attributeValueObject]
    );

    return groupBy(
        availableAttributesValues,
        'attributeId',
        (item: TVariantAttributeValue) => item.value
    );
};

const getVariantsAttributesValuesByVariantId = (
    variantsAttributesValues: TVariantAttributeValue[]
) => {
    return Object.values(
        groupBy(variantsAttributesValues, 'variantId') as TGroupedVariantAttributeValuesMap
    );
};

const omitVariantAttributesByAttributeValue = (
    variantsAttributesValues: TVariantAttributeValue[],
    attributeValue: TAttributeValueObject
) => {
    return variantsAttributesValues.filter(
        (variantAttribute) =>
            variantAttribute.attributeId !== attributeValue.attributeId &&
            variantAttribute.value !== attributeValue.value
    );
};

const getVariantAttributeValueByAttributeId = (
    variantAttributesValues: TVariantAttributeValue[],
    attributeId: number
) => {
    return variantAttributesValues.filter(
        (variantAttributeValue) => variantAttributeValue.attributeId === attributeId
    );
};

const setDefaultAttributesValuesByAttributeObject = (
    variantsAttributesValues: TVariantAttributeValue[],
    attributeValueObject: TAttributeValueObject | undefined
) => {
    if (!attributeValueObject) {
        return;
    }

    const variantId = findVariantByAttributeObject(variantsAttributesValues, attributeValueObject)
        ?.variantId;

    if (!variantId) {
        return;
    }

    return findAttributesValuesByVariantId(variantsAttributesValues, variantId);
};

const findVariantByAttributeObject = (
    variantsValues: TVariantAttributeValue[],
    attributeObject: TAttributeValueObject
) => {
    return variantsValues.find(
        (variantValue) =>
            variantValue.attributeId === attributeObject.attributeId &&
            variantValue.value === attributeObject.value
    );
};

const findAttributesValuesByVariantId = (
    variantsAttributesValues: TVariantAttributeValue[],
    variantId: number
) => {
    return arrayToObject(
        variantsAttributesValues.filter((variantValue) => variantValue.variantId === variantId),
        'attributeId',
        omit,
        [['variantId']]
    ) as Record<number, TAttributeValueObject>;
};

const isVariantInUrl = (location: WindowLocation, variantId?: number) => {
    const urlVariantId = getUrlVariantId(location.search);

    return variantId === urlVariantId;
};

const getUrlVariantId = (search: string) => Number(new URLSearchParams(search).get('variant'));

const navigateToVariant = async (location: WindowLocation, variantId: number) => {
    const params = new URLSearchParams(window.location.search);
    params.set('variant', variantId.toString());
    try {
        await navigate(`${location.pathname}?${params.toString()}`, {
            replace: true,
        });
    } catch {
        // eslint-disable-next-line no-console
        console.error(`Can't navigate to variant ${variantId}`);
    }
};
