import { graphql, useStaticQuery } from 'gatsby';

import { IFaq } from '../models/faq';
import QueryEdgeNode from '../models/query-edge-node.model';
import getNodes from '../utills/get-nodes';

interface IUseFaqQueryResult {
    allFaq: QueryEdgeNode<IFaq>;
}

export const useFaq = () => {
    const { allFaq } = useStaticQuery<IUseFaqQueryResult>(query);
    return getNodes(allFaq);
};

const query = graphql`
    query {
        allFaq {
            edges {
                node {
                    faqId
                    title
                    content
                }
            }
        }
    }
`;
