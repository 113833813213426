import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { config } from '../config';
import { accountType } from '../redux/profile/profile.selectors';
import { IProductAttribute } from '../models/product-attribute.model';
import { IProductVariant } from '../models/product-variant.model';
import { EProductKind } from '../models/product-kind.enum';

const { subscriptionMap } = config;

export interface IUseSubscriptionSpProps {
    kind: EProductKind;
    variants: IProductVariant[];
}

export default function useSubscriptionSp({ kind, variants }: IUseSubscriptionSpProps) {
    const userAccountType = useSelector(accountType);
    const [filteredVariants, setFilteredVariants] = useState(variants);

    const isSubscription = kind === EProductKind.Subscription;

    const renderAttributeListItemProps = useCallback(
        (attribute: IProductAttribute) => {
            const subscriberAccountType =
                userAccountType &&
                isSubscription &&
                attribute.attributeId === subscriptionMap.attributes.accountType.id;
            return {
                disabled: subscriberAccountType,
                tooltip:
                    subscriberAccountType &&
                    'Twój typ konta pasuje tylko do aktualnego typu subskrypcji.',
            };
        },
        [isSubscription, userAccountType]
    );

    useEffect(() => {
        if (!isSubscription || !userAccountType) {
            return;
        }

        setFilteredVariants(filterVariantsByAccountType(variants, userAccountType));
    }, [isSubscription, userAccountType, variants]);

    return {
        isSubscriptionSp: isSubscription && variants?.length > 0,
        renderAttributeListItemProps,
        variants: filteredVariants,
    };
}

const filterVariantsByAccountType = (variants: IProductVariant[], accountType: number | null) => {
    return variants.filter((variant) =>
        variant.attributesValues.find(
            (attributeValue) =>
                attributeValue.attributeId === subscriptionMap.attributes.accountType.id &&
                attributeValue.value === accountType
        )
    );
};
