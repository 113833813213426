import React from 'react';

import { container } from './product-description.module.scss';

import Markdown from '../hoc/markdown';

interface IProductDescriptionProps {
    className?: string;
    description: string;
}

const ProductDescription: React.FC<IProductDescriptionProps> = ({
    className = '',
    description,
}) => {
    return (
        <Markdown className={`${container} ${className} ck-content`}>
            {getSourceForMarkdown(description)}
        </Markdown>
    );
};

function getSourceForMarkdown(description: string) {
    const contentWithBreakLines = description?.split('{{').join('\n\n{{');
    return contentWithBreakLines?.split('}}').join('}}\n\n');
}

export default ProductDescription;
